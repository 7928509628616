import React, { createContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { WorkspaceMin } from '../graphql/graphql'
import { GET_MY_WORKSPACES } from '../graphql/workspace'

export type WorkspaceState = {
  other?: string
  loading: boolean
} & { workspaces: WorkspaceMin[] }

export const WorkspaceStateContext = createContext<WorkspaceState>(
  {} as WorkspaceState
)

export const WorkspaceStateProvider: React.FC = ({ children }) => {
  const [workspacesState, setWorkspacesState] = useState<WorkspaceMin[]>([])
  const { data, loading } = useQuery<{ myWorkspaces: WorkspaceMin[] }>(
    GET_MY_WORKSPACES
  )

  useEffect(() => {
    const cleanup = () => {
      if (data && Array.isArray(data.myWorkspaces)) {
        setWorkspacesState(data.myWorkspaces)
      }
    }

    cleanup()
    return () => {
      cleanup()
    }
  }, [data])

  return (
    <WorkspaceStateContext.Provider
      value={{ workspaces: workspacesState, loading }}
    >
      {children}
    </WorkspaceStateContext.Provider>
  )
}
export const useWorkspace = (): WorkspaceState =>
  React.useContext(WorkspaceStateContext)
