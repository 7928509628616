import React from 'react'
import Routes from './routes'
import { useKeycloak } from '@react-keycloak/web'
import SplashScreen from './components/SplashScreen'

const App: React.FC = () => {
  const { initialized } = useKeycloak()

  if (!initialized) {
    return <SplashScreen />
  }

  return <Routes />
}

export default App
