/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Route, Redirect, RouteComponentProps } from 'react-router-dom'
import type { RouteProps } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'

interface PrivateRouteParams extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
}
interface PrivateRouteParams extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
}

export function PrivateRoute({
  component: Component,
  ...rest
}: PrivateRouteParams): any {
  const { keycloak } = useKeycloak()

  const authorizeByRole = (role?: string) => {
    if (keycloak.authenticated && role && keycloak.hasRealmRole(role)) {
      return true
    } else if (keycloak.authenticated) {
      return true
    } else {
      return false
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        // eslint-disable-next-line multiline-ternary
        authorizeByRole() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}
