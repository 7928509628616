import React from 'react'
import { createStyles, makeStyles, Slide, Theme } from '@material-ui/core'
import { ReactComponent as LoadingSvg } from '../Assets/loading.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      background: `${theme.palette.primary.main}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
)

const SplashScreen: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Slide
        direction="right"
        in={true}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 500, appear: 200, exit: 500 }}
      >
        <LoadingSvg style={{ color: 'white' }} />
      </Slide>
    </div>
  )
}

export default SplashScreen
