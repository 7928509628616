import React, { lazy } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import { PrivateRoute } from './privateRoute'
import { createBrowserHistory } from 'history'
import { ProvidersWrapper } from '../providers'

const Public = lazy(() => import('../pages/Public'))
const DashboardRoutes = lazy(() => import('./DashboardRoutes'))

export const history = createBrowserHistory()

const Routes: React.FC = () => {
  return (
    <ProvidersWrapper>
      <Router>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />
          <PrivateRoute path="/dashboard" component={DashboardRoutes} />
          <Route exact path="/login" component={Public} />
          <Route path="*" component={() => <h1>Page Not Found - 404</h1>} />
        </Switch>
      </Router>
    </ProvidersWrapper>
  )
}

export default Routes
