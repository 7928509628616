import React, { createContext, useEffect, useState } from 'react'
import { WorkspaceMin } from '../graphql/graphql'
import { localStorageAdapter } from './localStorageAdapter'
import { getWorkSpace, setWorkSpace } from './workSpace'
import { useWorkspace } from './workspaceProvider'

export type CurrentWorkspaceState = {
  other?: string
  changeCurrentWorkspace(id: string): void
} & { workspace: WorkspaceMin | undefined }

interface CurrentWorkspaceProviderProps {
  changeWorkspaceId(id?: string): void
}

export const CurrentWorkspaceStateContext = createContext<CurrentWorkspaceState>(
  {} as CurrentWorkspaceState
)

export const CurrentWorkspaceProvider: React.FC<CurrentWorkspaceProviderProps> = ({
  children,
  changeWorkspaceId
}) => {
  const [currentWorkspace, setCurrentWorkspace] = useState<
    WorkspaceMin | undefined
  >(undefined)
  const { workspaces } = useWorkspace()

  const changeCurrentWorkspace = (id: string) => {
    const workspaceChangeFilter = workspaces.find(w => w._id === id)
    setCurrentWorkspace(workspaceChangeFilter)
    setWorkSpace(localStorageAdapter, id)
    changeWorkspaceId(id)
  }

  useEffect(() => {
    const cleanup = () => {
      const workspaceIdFromLocalStorage = getWorkSpace(localStorageAdapter)
      if (workspaceIdFromLocalStorage) {
        const workspace = workspaces.find(
          w => w._id === workspaceIdFromLocalStorage
        )
        setCurrentWorkspace(workspace)
        changeCurrentWorkspace(workspace?._id || '')
      } else if (workspaces.length === 1) {
        changeCurrentWorkspace(workspaces[0]._id)
      }
    }

    if (workspaces) {
      cleanup()
    }
    return () => {
      cleanup()
    }
  }, [workspaces])

  return (
    <CurrentWorkspaceStateContext.Provider
      value={{ workspace: currentWorkspace, changeCurrentWorkspace }}
    >
      {children}
    </CurrentWorkspaceStateContext.Provider>
  )
}
export const useCurrentWorkspace = (): CurrentWorkspaceState =>
  React.useContext(CurrentWorkspaceStateContext)
