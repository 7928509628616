import React, { createContext, useState, useContext } from 'react'

type DefaultConfigure = {
  PersonalConfig: boolean
  TypeDraw: string
  Pause: string
  TypePause: string
  TimeBreak: number
  TimeResult: number
  UnitGroup: string[]
  Tags: string[]
}

type PropsConfigureContext = {
  configure: DefaultConfigure
  setConfigure: React.Dispatch<React.SetStateAction<DefaultConfigure>>
}

export const DEFAULT_VALUE = {
  configure: {
    PersonalConfig: false,
    TypeDraw: 'Automatico',
    Pause: 'Com pausa',
    TypePause: 'Unidade',
    TimeBreak: 5,
    TimeResult: 30,
    UnitGroup: [''],
    Tags: ['']
  },
  setConfigure: () => null
}

const ConfigureDrawContext = createContext<PropsConfigureContext>(DEFAULT_VALUE)

export const ConfigureDrawProvider: React.FC = ({ children }) => {
  const [configure, setConfigure] = useState(DEFAULT_VALUE.configure)
  return (
    <ConfigureDrawContext.Provider
      value={{
        configure,
        setConfigure
      }}
    >
      {children}
    </ConfigureDrawContext.Provider>
  )
}

export function useConfigureDraw() {
  const context = useContext(ConfigureDrawContext)
  const { configure, setConfigure } = context
  return { configure, setConfigure }
}

export default ConfigureDrawContext
