import React from 'react'

import { ConfigureDrawProvider } from './configureDraw/context'

const GlobalContext: React.FC = ({ children }) => {
  return (
    <>
      <ConfigureDrawProvider>{children}</ConfigureDrawProvider>
    </>
  )
}

export default GlobalContext
