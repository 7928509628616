type db = {
  get: (key: string) => string | null
  set: (key: string, data: string) => void
}

export function getWorkSpace(db: db): string | null {
  const workspace = db.get('workspace')
  return workspace
}

export function setWorkSpace(db: db, data: string): void {
  return db.set('workspace', data)
}
