import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak, { keycloakInitConfig } from './providers/keycloak'
import reportWebVitals from './reportWebVitals'
import theme from './theme'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import GlobalContext from './context'
import SplashScreen from './components/SplashScreen'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakInitConfig}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalContext>
          <Suspense fallback={<SplashScreen />}>
            <App />
            <ToastContainer />
          </Suspense>
        </GlobalContext>
      </ThemeProvider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
