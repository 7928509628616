import React, { useState } from 'react'
import { ApolloConnectProvider } from './apollo'
import { CurrentWorkspaceProvider } from './currentWorkspaceProvider'
import { WorkspaceStateProvider } from './workspaceProvider'

interface ProvidersWrapperProps {
  children: React.ReactNode
}

export const ProvidersWrapper = ({
  children
}: ProvidersWrapperProps): JSX.Element => {
  const [currentId, setCurrentId] = useState('')

  return (
    <ApolloConnectProvider currentId={currentId}>
      <WorkspaceStateProvider>
        <CurrentWorkspaceProvider
          changeWorkspaceId={id => setCurrentId(id || '')}
        >
          {children}
        </CurrentWorkspaceProvider>
      </WorkspaceStateProvider>
    </ApolloConnectProvider>
  )
}
