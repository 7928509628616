import React from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink
} from '@apollo/client'
import { useKeycloak } from '@react-keycloak/web'
import { onError } from '@apollo/client/link/error'
import { AppConfig } from '../config'
import { toast } from 'react-toastify'

interface ApolloProviderProps {
  currentId: string | null
}

export const ApolloConnectProvider: React.FC<ApolloProviderProps> = ({
  children,
  currentId
}) => {
  const { keycloak } = useKeycloak()

  const token = keycloak?.token

  const httpLink = new HttpLink({
    uri: AppConfig.backEndUrl,
    headers: {
      authorization: `Bearer ${token}`,
      'x-workspace': currentId
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, extensions }) => {
        if (extensions && extensions.code === 'E_UNAUTHORIZED') {
          toast.error('Não autorizado', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })
          setTimeout(() => {
            if (keycloak) {
              return keycloak?.logout()
            }
          }, 5000)
        }
        if (extensions && extensions.validation) {
          extensions.validation.forEach((validation: { message: string }) => {
            toast.error(`${validation.message}`, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            })
          })
        }
        toast.error(`${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
        return console.error(extensions)
      })
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }
  })

  console.log('CONNECT_APOLLO')

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, httpLink])
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
