import { gql } from '@apollo/client'

export const GET_MY_WORKSPACES = gql`
  query myWorkspaces {
    myWorkspaces(forceAll: true) {
      _id
      name
      slug
    }
  }
`

export const REGISTER_MY_WORKSPACES = gql`
  mutation RegisterWorkspaceMutation($input: RegisterWorkspaceInput!) {
    registerWorkspace(input: $input) {
      message
      workspace {
        _id
      }
    }
  }
`
